import React from "react";

// eslint-disable-next-line react/require-default-props
function ExportMusicIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clipPath="url(#clip0_885_15664)">
        <path
          opacity="0.5"
          d="M9.00015 17.9998C6.59606 17.9998 4.33607 17.0636 2.63614 15.3637C0.936211 13.664 0 11.4037 0 8.99995C0 8.57381 0.345293 8.22852 0.771433 8.22852C1.19757 8.22852 1.54287 8.57381 1.54287 8.99995C1.54287 13.112 4.88811 16.4572 9.00015 16.4572C13.1122 16.4572 16.4574 13.112 16.4574 8.99995C16.4574 8.57381 16.8027 8.22852 17.2289 8.22852C17.655 8.22852 18.0003 8.57381 18.0003 8.99995C18.0003 11.404 17.0641 13.664 15.3642 15.364C13.6642 17.0639 11.4042 18.0001 9.00015 18.0001V17.9998Z"
          fill="#0BD1C9"
        />
        <path
          d="M8.99974 11.8286C8.5736 11.8286 8.22831 11.4833 8.22831 11.0572V2.63403L6.45957 4.40277C6.1584 4.70394 5.66993 4.70394 5.36845 4.40277C5.06729 4.10161 5.06729 3.61313 5.36845 3.31166L8.45388 0.226235C8.45388 0.226235 8.45635 0.223766 8.45758 0.222532C8.45758 0.222532 8.45789 0.222224 8.4582 0.221915C8.4582 0.221915 8.4582 0.221915 8.45851 0.221606C8.53133 0.150017 8.61464 0.0960171 8.70382 0.0592969C8.79238 0.0225767 8.88927 0.00190228 8.99079 0.000976562C8.9911 0.000976562 8.99141 0.000976562 8.99172 0.000976562C8.99203 0.000976562 8.99234 0.000976562 8.99265 0.000976562C8.99666 0.000976562 9.00098 0.000976562 9.00499 0.000976562C9.0053 0.000976562 9.00561 0.000976562 9.00591 0.000976562C9.00622 0.000976562 9.00653 0.000976562 9.00684 0.000976562C9.10836 0.00190228 9.20525 0.0228853 9.29381 0.0592969C9.38268 0.0960171 9.46631 0.150326 9.53913 0.221606C9.53913 0.221606 9.53913 0.221606 9.53944 0.221915C9.53944 0.221915 9.53975 0.222224 9.54005 0.222532C9.54129 0.223766 9.54252 0.225001 9.54376 0.226235L12.6292 3.31166C12.9303 3.61283 12.9303 4.1013 12.6292 4.40277C12.328 4.70394 11.8395 4.70394 11.5381 4.40277L9.76932 2.63403V11.0572C9.76932 11.4833 9.42403 11.8286 8.99789 11.8286H8.99974Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_885_15664">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ExportMusicIcon;
