import React from "react";

// eslint-disable-next-line react/require-default-props
function InstagramIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_885_15768)">
        <path
          d="M12.7905 20H7.20918C4.63198 20 2.88668 19.4547 1.71599 18.284C0.5453 17.1133 0 15.3677 0 12.7905V7.20918C0 4.63226 0.5453 2.88668 1.71599 1.71599C2.88668 0.5453 4.63226 0 7.20918 0H12.7905C15.3677 0 17.113 0.5453 18.2837 1.71599C19.4544 2.88668 19.9997 4.63226 19.9997 7.20918V12.7905C19.9997 15.3677 19.4544 17.113 18.2837 18.2837C17.113 19.4544 15.3675 19.9997 12.7905 19.9997V20ZM7.20918 1.39534C5.0263 1.39534 3.5944 1.81088 2.7025 2.7025C1.8106 3.5944 1.39534 5.0263 1.39534 7.20918V12.7905C1.39534 14.9734 1.81088 16.4053 2.7025 17.2972C3.5944 18.1891 5.0263 18.6044 7.20918 18.6044H12.7905C14.9734 18.6044 16.4053 18.1891 17.2972 17.2972C18.1891 16.4053 18.6044 14.9734 18.6044 12.7905V7.20918C18.6044 5.0263 18.1891 3.5944 17.2972 2.7025C16.4053 1.8106 14.9734 1.39534 12.7905 1.39534H7.20918Z"
          fill="white"
        />
        <path
          d="M9.99946 13.953C7.81938 13.953 6.0459 12.1795 6.0459 9.99946C6.0459 7.81938 7.81938 6.0459 9.99946 6.0459C12.1795 6.0459 13.953 7.81938 13.953 9.99946C13.953 12.1795 12.1795 13.953 9.99946 13.953ZM9.99946 7.44152C8.58905 7.44152 7.44124 8.58905 7.44124 9.99974C7.44124 11.4104 8.58877 12.558 9.99946 12.558C11.4102 12.558 12.5577 11.4104 12.5577 9.99974C12.5577 8.58905 11.4102 7.44152 9.99946 7.44152Z"
          fill="white"
        />
        <path
          d="M15.2535 6.27922H15.2426C14.7289 6.27922 14.3125 5.86285 14.3125 5.34908C14.3125 4.83532 14.7289 4.41895 15.2426 4.41895H15.2535C15.7673 4.41895 16.1837 4.83532 16.1837 5.34908C16.1837 5.86285 15.7673 6.27922 15.2535 6.27922Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_885_15768">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default InstagramIcon;
