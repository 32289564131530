import React from "react";

// eslint-disable-next-line react/require-default-props
function AddToLivePadsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_836_13287)">
        <path
          opacity="0.5"
          d="M5.39659 19.177C5.13896 19.177 4.89721 19.1506 4.65699 19.0946C3.87335 18.912 3.16832 18.505 2.61824 17.9176C1.92397 17.1762 1.65149 16.1595 1.10883 14.1338L0.667065 12.4845C0.124148 10.4588 -0.148335 9.44216 0.0824051 8.45313C0.265255 7.66948 0.672187 6.96446 1.25941 6.41437C2.0008 5.7201 3.01749 5.44762 5.04292 4.90496C5.40145 4.80893 5.73975 4.71827 6.04348 4.63965L6.36411 5.87939C6.06576 5.95648 5.73002 6.04637 5.37482 6.14163C3.55528 6.62923 2.64205 6.8738 2.13499 7.34886C1.73318 7.72531 1.45455 8.20753 1.32958 8.74379C1.17182 9.42065 1.41639 10.3339 1.90399 12.1532L2.34601 13.8024C2.83361 15.6217 3.07818 16.5352 3.55323 17.0422C3.92969 17.444 4.41191 17.7224 4.94817 17.8474C5.62502 18.0051 6.53825 17.7606 8.35779 17.273C10.1776 16.7854 11.0906 16.5405 11.5976 16.0657C11.9377 15.7474 12.1938 15.3448 12.3382 14.9021L13.5555 15.2995C13.3442 15.9467 12.9698 16.5349 12.4727 17.0002C11.7316 17.6945 10.7149 17.967 8.68969 18.5096C7.15543 18.9207 6.19995 19.1768 5.39684 19.1768L5.39659 19.177Z"
          fill="#0BD1C9"
        />
        <path
          d="M12.6018 15.7621C11.7969 15.7621 10.8465 15.5075 9.31153 15.0962C7.28481 14.553 6.26812 14.2806 5.52699 13.5865C4.93977 13.0365 4.53258 12.3314 4.34998 11.5478C4.11924 10.5585 4.39173 9.54182 4.93464 7.51638L5.37666 5.86714C5.45041 5.5921 5.52007 5.33216 5.58563 5.09169C5.96286 3.71058 6.25711 2.7551 6.88607 2.08363C7.43616 1.49641 8.14118 1.08922 8.92483 0.906624C9.91386 0.675884 10.9305 0.948367 12.9565 1.49128C14.9822 2.0342 15.9989 2.30643 16.7403 3.0007C17.3277 3.55078 17.7347 4.25581 17.9173 5.03945C18.148 6.02848 17.8755 7.04543 17.3326 9.07086L16.8906 10.7201C16.3479 12.7455 16.0755 13.7622 15.3812 14.5039C14.8311 15.0911 14.1261 15.4983 13.3424 15.6809C13.2387 15.7052 13.1314 15.7242 13.0238 15.7372C12.8863 15.7541 12.7465 15.7623 12.6021 15.7623L12.6018 15.7621ZM9.66545 2.10539C9.5018 2.10539 9.35353 2.12076 9.21524 2.15303C8.67898 2.278 8.19675 2.55663 7.8203 2.95844C7.42771 3.37766 7.19441 4.05938 6.82051 5.42871C6.75572 5.66611 6.68632 5.92451 6.61308 6.19827L6.17106 7.84751C5.68346 9.66679 5.43889 10.58 5.59664 11.2569C5.72162 11.7931 6.00025 12.2754 6.40206 12.6518C6.90912 13.1269 7.82209 13.3714 9.64112 13.8588C11.298 14.3028 12.21 14.5469 12.8674 14.466C12.9309 14.4583 12.9908 14.4475 13.0513 14.4334C13.5878 14.3085 14.07 14.0298 14.4464 13.628C14.9215 13.1207 15.1663 12.2075 15.6537 10.3882L16.0957 8.73897C16.5833 6.91968 16.8279 6.00646 16.6701 5.3296C16.5451 4.79335 16.2668 4.31112 15.8647 3.93467C15.3574 3.45961 14.4444 3.21504 12.6249 2.72744C11.1772 2.33946 10.3031 2.10539 9.66545 2.10539Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_836_13287">
          <rect
            width="18"
            height="18.3534"
            fill="white"
            transform="translate(0 0.823242)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AddToLivePadsIcon;
