import React from "react";

// eslint-disable-next-line react/require-default-props
function DeleteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_831_124254)">
        <path
          opacity="0.5"
          d="M8.99987 18C7.78526 18 6.60656 17.7619 5.49642 17.2925C4.42471 16.8391 3.46226 16.1904 2.63594 15.3641C1.80962 14.5377 1.16087 13.5753 0.707522 12.5036C0.238101 11.3934 0 10.2147 0 8.99987C0 7.78501 0.238101 6.60656 0.707522 5.49642C1.16087 4.42471 1.80962 3.46226 2.63594 2.63594C3.46226 1.80962 4.42471 1.16087 5.49667 0.707522C6.60656 0.238101 7.78526 0 8.99987 0C10.2145 0 11.3932 0.238101 12.5033 0.707522C13.575 1.16087 14.5375 1.80962 15.3638 2.63594C16.1901 3.46226 16.8389 4.42471 17.2922 5.49642C17.7616 6.6063 17.9997 7.78501 17.9997 8.99987C17.9997 10.2147 17.7616 11.3932 17.2922 12.5033C16.8389 13.575 16.1901 14.5375 15.3638 15.3638C14.5375 16.1901 13.575 16.8389 12.5033 17.2922C11.3934 17.7616 10.2147 17.9997 8.99987 17.9997V18ZM8.99987 1.25581C4.72988 1.25581 1.25581 4.72988 1.25581 8.99987C1.25581 13.2699 4.72988 16.7439 8.99987 16.7439C13.2699 16.7439 16.7439 13.2699 16.7439 8.99987C16.7439 4.72988 13.2701 1.25581 8.99987 1.25581Z"
          fill="#0BD1C9"
        />
        <path
          d="M6.9072 11.7213C6.74646 11.7213 6.58571 11.66 6.46315 11.5375C6.21801 11.2923 6.21801 10.8947 6.46315 10.6494L8.11227 9.00048L6.46315 7.35135C6.21801 7.10621 6.21801 6.70863 6.46315 6.46324C6.70828 6.21786 7.10587 6.21811 7.35125 6.46324L9.00038 8.11237L10.6495 6.46324C10.8946 6.21811 11.2922 6.21811 11.5376 6.46324C11.7828 6.70838 11.7828 7.10596 11.5376 7.35135L9.88849 9.00022L11.5376 10.6494C11.7828 10.8945 11.7828 11.2921 11.5376 11.5375C11.2925 11.7826 10.8949 11.7826 10.6495 11.5375L9.00038 9.88833L7.35125 11.5375C7.22869 11.66 7.06794 11.7213 6.9072 11.7213Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_831_124254">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DeleteIcon;
