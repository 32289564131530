import React from "react";

// eslint-disable-next-line react/require-default-props
function VolumeLoudIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.00249 9.76259C2.03361 8.22739 2.04917 7.4598 2.55911 6.8027C2.65218 6.68275 2.78875 6.54025 2.90321 6.44362C3.53032 5.91418 4.35829 5.91418 6.01422 5.91418C6.60583 5.91418 6.90164 5.91418 7.18363 5.83677C7.24223 5.82069 7.3002 5.80214 7.35742 5.78118C7.6328 5.68029 7.87978 5.50665 8.37376 5.15935C10.3227 3.78915 11.2971 3.10406 12.115 3.40168C12.2717 3.45874 12.4236 3.5411 12.5592 3.64269C13.2662 4.17254 13.3199 5.4053 13.4273 7.87081C13.4672 8.78375 13.4943 9.56509 13.4943 9.99967C13.4943 10.4343 13.4672 11.2156 13.4273 12.1285C13.3199 14.594 13.2662 15.8268 12.5592 16.3567C12.4236 16.4583 12.2717 16.5406 12.115 16.5977C11.2971 16.8953 10.3227 16.2102 8.37376 14.84C7.87978 14.4927 7.6328 14.3191 7.35742 14.2182C7.3002 14.1972 7.24223 14.1787 7.18363 14.1626C6.90164 14.0852 6.60583 14.0852 6.01422 14.0852C4.35829 14.0852 3.53032 14.0852 2.90321 13.5558C2.78875 13.4591 2.65218 13.3166 2.55911 13.1967C2.04917 12.5395 2.03361 11.7719 2.00249 10.2368C2.00086 10.1562 2 10.077 2 9.99967C2 9.92234 2.00086 9.84317 2.00249 9.76259Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5759 4.62643C16.8198 4.40976 17.1822 4.44489 17.3853 4.7049L16.9438 5.09721C17.3853 4.7049 17.3852 4.70462 17.3853 4.7049L17.3863 4.70602L17.3873 4.70728L17.3895 4.71023L17.3953 4.71781C17.3996 4.72361 17.405 4.73091 17.4114 4.73972C17.4243 4.75735 17.4409 4.78104 17.4609 4.81092C17.5009 4.87068 17.5543 4.95516 17.6163 5.06535C17.7403 5.28581 17.8988 5.60871 18.055 6.04159C18.3678 6.90883 18.668 8.20907 18.668 9.99977C18.668 11.7905 18.3678 13.0908 18.055 13.958C17.8988 14.3909 17.7403 14.7138 17.6163 14.9343C17.5543 15.0444 17.5009 15.1289 17.4609 15.1887C17.4409 15.2185 17.4243 15.2423 17.4114 15.2599C17.405 15.2687 17.3996 15.276 17.3953 15.2818L17.3895 15.2894L17.3873 15.2924L17.3863 15.2936C17.386 15.2939 17.3853 15.2947 16.9438 14.9024L17.3853 15.2947C17.1822 15.5547 16.8198 15.5899 16.5759 15.3732C16.3333 15.1575 16.2995 14.7738 16.4994 14.5138C16.4999 14.5132 16.5008 14.5119 16.5021 14.5102C16.5062 14.5046 16.5142 14.4934 16.5256 14.4763C16.5484 14.4422 16.5849 14.385 16.6307 14.3037C16.7221 14.1411 16.8509 13.8819 16.9822 13.518C17.2441 12.7919 17.5186 11.6409 17.5186 9.99977C17.5186 8.35869 17.2441 7.20767 16.9822 6.48157C16.8509 6.11778 16.7221 5.85849 16.6307 5.69594C16.5849 5.61462 16.5484 5.55739 16.5256 5.52331C16.5142 5.50627 16.5062 5.49501 16.5021 5.48942C16.5008 5.48761 16.4999 5.48641 16.4994 5.48577"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1316 7.01375C15.4091 6.84939 15.759 6.95599 15.9131 7.25185L15.4108 7.54946C15.9131 7.25184 15.913 7.25158 15.9131 7.25185L15.9137 7.25292L15.9143 7.25406L15.9155 7.25655L15.9185 7.26236L15.9258 7.27735C15.9314 7.28885 15.9383 7.30336 15.9461 7.32094C15.9618 7.35609 15.9817 7.40349 16.0042 7.46358C16.0493 7.58383 16.1049 7.75455 16.159 7.97943C16.2673 8.42963 16.3686 9.09371 16.3686 10.0007C16.3686 10.9078 16.2673 11.5719 16.159 12.022C16.1049 12.247 16.0493 12.4177 16.0042 12.538C15.9817 12.598 15.9618 12.6454 15.9461 12.6805C15.9383 12.6981 15.9314 12.7126 15.9258 12.7241L15.9185 12.7391L15.9155 12.745L15.9143 12.7475L15.9137 12.7486C15.9135 12.7489 15.9131 12.7496 15.4108 12.452L15.9131 12.7496C15.759 13.0455 15.4091 13.1521 15.1316 12.9877C14.8565 12.8248 14.7559 12.4566 14.9045 12.162L14.9083 12.1536C14.9136 12.1419 14.9236 12.1184 14.937 12.0827C14.9638 12.0115 15.0039 11.8911 15.0456 11.7176C15.1289 11.3711 15.2192 10.8095 15.2192 10.0007C15.2192 9.19196 15.1289 8.63038 15.0456 8.28385C15.0039 8.1104 14.9638 7.99003 14.937 7.91877C14.9236 7.88311 14.9136 7.85965 14.9083 7.84789L14.9045 7.83952C14.7559 7.54489 14.8565 7.17673 15.1316 7.01375Z"
        fill="white"
      />
    </svg>
  );
}

export default VolumeLoudIcon;
