import React from "react";

// eslint-disable-next-line react/require-default-props
function CrossIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.97619 10.0351C1.70829 10.0351 1.44038 9.93297 1.2361 9.72869C0.827548 9.32014 0.827548 8.65749 1.2361 8.24851L3.98465 5.50039L1.2361 2.75184C0.827548 2.34328 0.827548 1.68064 1.2361 1.27166C1.64466 0.862687 2.30731 0.863106 2.71628 1.27166L5.46483 4.02021L8.21337 1.27166C8.62193 0.863106 9.28458 0.863106 9.69355 1.27166C10.1021 1.68022 10.1021 2.34287 9.69355 2.75184L6.94501 5.49997L9.69355 8.24851C10.1021 8.65707 10.1021 9.31972 9.69355 9.72869C9.285 10.1372 8.62235 10.1372 8.21337 9.72869L5.46483 6.98015L2.71628 9.72869C2.51201 9.93297 2.2441 10.0351 1.97619 10.0351Z"
        fill="white"
      />
    </svg>
  );
}

export default CrossIcon;
