import React from "react";

// eslint-disable-next-line react/require-default-props
function EffectsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1262_56942)">
        <path
          d="M8.99987 18C6.97074 18 5.50446 18 4.32299 17.841C2.9951 17.6624 2.0967 17.2767 1.40977 16.59C0.723094 15.9033 0.33731 15.0049 0.158734 13.677C0 12.4953 0 11.0293 0 8.99987C0 6.97049 0 5.50471 0.158734 4.32299C0.33731 2.9951 0.723094 2.0967 1.40977 1.40977C2.0967 0.723345 2.9951 0.33731 4.32299 0.158734C5.50471 0 6.97074 0 8.99987 0C11.029 0 12.4953 0 13.6768 0.158734C15.0046 0.33731 15.9031 0.723094 16.5897 1.40977C17.2764 2.09645 17.6624 2.99485 17.8408 4.32274C17.9997 5.50421 17.9997 6.97049 17.9997 8.99962C17.9997 11.0288 17.9997 12.495 17.8408 13.6765C17.6622 15.0044 17.2764 15.9028 16.5895 16.5897C15.9028 17.2764 15.0044 17.6624 13.6765 17.8408C12.495 17.9998 11.029 18 8.99987 18ZM8.99987 1.25581C5.23145 1.25581 3.33995 1.25581 2.29788 2.29788C1.25581 3.33995 1.25581 5.23145 1.25581 8.99987C1.25581 12.7683 1.25581 14.6598 2.29788 15.7019C3.33995 16.7442 5.23145 16.7442 8.99987 16.7442C12.7683 16.7442 14.6598 16.7442 15.7019 15.7021C16.7442 14.6601 16.7442 12.7686 16.7442 9.00013C16.7442 5.2317 16.7442 3.3402 15.7021 2.29813C14.6601 1.25606 12.7683 1.25581 8.99987 1.25581Z"
          fill="#187B7D"
        />
        <path
          d="M3.97654 5.65098V5.02308C3.62968 5.02308 3.34863 5.30413 3.34863 5.65098H3.97654ZM7.32528 6.27889C7.67213 6.27889 7.95318 5.99784 7.95318 5.65098C7.95318 5.30413 7.67213 5.02308 7.32528 5.02308V6.27889ZM7.32528 9.62763C7.67213 9.62763 7.95318 9.34658 7.95318 8.99972C7.95318 8.65287 7.67213 8.37182 7.32528 8.37182V9.62763ZM14.5253 6.02773C14.7333 5.75019 14.6773 5.35662 14.3997 5.14866C14.1222 4.94045 13.7286 4.99671 13.5207 5.27424L14.5253 6.02773ZM8.49745 11.972C8.28948 12.2495 8.34549 12.6431 8.62303 12.851C8.90056 13.059 9.29413 13.003 9.50209 12.7255L8.49745 11.972ZM9.50209 5.27424C9.29413 4.99671 8.90056 4.94045 8.62303 5.14866C8.34549 5.35662 8.28948 5.75019 8.49745 6.02773L9.50209 5.27424ZM13.5207 12.7255C13.7286 13.003 14.1222 13.059 14.3997 12.851C14.6773 12.6431 14.7333 12.2495 14.5253 11.972L13.5207 12.7255ZM3.34863 12.3487C3.34863 12.6956 3.62968 12.9766 3.97654 12.9766C4.32339 12.9766 4.60444 12.6956 4.60444 12.3487H3.34863ZM3.97654 6.27889H7.32528V5.02308H3.97654V6.27889ZM3.97654 9.62763H7.32528V8.37182H3.97654V9.62763ZM13.5207 5.27424L11.0091 8.62298L12.0137 9.37646L14.5253 6.02773L13.5207 5.27424ZM11.0091 8.62298L8.49745 11.9717L9.50209 12.7252L12.0137 9.37646L11.0091 8.62298ZM8.49745 6.02773L11.0091 9.37646L12.0137 8.62298L9.50209 5.27424L8.49745 6.02773ZM11.0091 9.37646L13.5207 12.7252L14.5253 11.9717L12.0137 8.62298L11.0091 9.37646ZM3.34863 5.65098V8.99972H4.60444V5.65098H3.34863ZM3.34863 8.99972V12.3485H4.60444V8.99972H3.34863Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1262_56942">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EffectsIcon;
