export default [
  {
    id: "closed_hihat_id",
    type: "closed_hihat",
    sounds: [
      "/samples/truncated_database/truncated_closed_hihat/1678847911.8688908.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678848125.342535.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678850493.821581.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678866568.216923.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678869386.6667182.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678872970.613287.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678877631.32613.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678879620.1371853.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678879717.7181864.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678883596.4608905.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678887124.5215006.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678887744.254473.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678893005.0204349.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678893643.763283.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678893705.352245.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678896240.3003783.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678899673.43457.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678901140.1560829.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678904995.5152354.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678913031.8720102.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678916558.7005088.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678918744.0544622.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678923375.0976734.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678927926.0569847.wav",
      "/samples/truncated_database/truncated_closed_hihat/1678932789.7240603.wav",
    ],
  },
  {
    id: "drums_id",
    type: "drums",
    sounds: [
      "/samples/truncated_database/truncated_drums/1678842238.310978.wav",
      "/samples/truncated_database/truncated_drums/1678843523.0856516.wav",
      "/samples/truncated_database/truncated_drums/1678843665.735809.wav",
      "/samples/truncated_database/truncated_drums/1678846152.3495314.wav",
      "/samples/truncated_database/truncated_drums/1678852392.6524186.wav",
      "/samples/truncated_database/truncated_drums/1678852995.4041815.wav",
      "/samples/truncated_database/truncated_drums/1678857989.7028198.wav",
      "/samples/truncated_database/truncated_drums/1678860212.6829588.wav",
      "/samples/truncated_database/truncated_drums/1678860840.2759666.wav",
      "/samples/truncated_database/truncated_drums/1678874072.8976274.wav",
      "/samples/truncated_database/truncated_drums/1678875143.6210475.wav",
      "/samples/truncated_database/truncated_drums/1678883438.837163.wav",
      "/samples/truncated_database/truncated_drums/1678884032.3872082.wav",
      "/samples/truncated_database/truncated_drums/1678885955.8104584.wav",
      "/samples/truncated_database/truncated_drums/1678890725.2185254.wav",
      "/samples/truncated_database/truncated_drums/1678894844.961121.wav",
      "/samples/truncated_database/truncated_drums/1678897280.1176329.wav",
      "/samples/truncated_database/truncated_drums/1678905876.0349905.wav",
      "/samples/truncated_database/truncated_drums/1678906825.3788338.wav",
      "/samples/truncated_database/truncated_drums/1678909454.302196.wav",
      "/samples/truncated_database/truncated_drums/1678921568.3922942.wav",
      "/samples/truncated_database/truncated_drums/1678926432.5048633.wav",
      "/samples/truncated_database/truncated_drums/1678928738.4217684.wav",
      "/samples/truncated_database/truncated_drums/1678932652.4471724.wav",
      "/samples/truncated_database/truncated_drums/1678935821.8750036.wav",
    ],
  },
  {
    id: "kick_id",
    type: "kick",
    sounds: [
      "/samples/truncated_database/truncated_kick/1678847825.3264084.wav",
      "/samples/truncated_database/truncated_kick/1678849885.6799846.wav",
      "/samples/truncated_database/truncated_kick/1678852442.8508997.wav",
      "/samples/truncated_database/truncated_kick/1678853074.3641357.wav",
      "/samples/truncated_database/truncated_kick/1678853380.0697203.wav",
      "/samples/truncated_database/truncated_kick/1678853412.4773045.wav",
      "/samples/truncated_database/truncated_kick/1678857386.2068977.wav",
      "/samples/truncated_database/truncated_kick/1678861922.9907722.wav",
      "/samples/truncated_database/truncated_kick/1678868117.9809086.wav",
      "/samples/truncated_database/truncated_kick/1678878005.794276.wav",
      "/samples/truncated_database/truncated_kick/1678879495.7916267.wav",
      "/samples/truncated_database/truncated_kick/1678881024.9987533.wav",
      "/samples/truncated_database/truncated_kick/1678884701.455819.wav",
      "/samples/truncated_database/truncated_kick/1678889472.3708642.wav",
      "/samples/truncated_database/truncated_kick/1678889752.7857192.wav",
      "/samples/truncated_database/truncated_kick/1678889801.2368634.wav",
      "/samples/truncated_database/truncated_kick/1678893433.9617984.wav",
      "/samples/truncated_database/truncated_kick/1678895125.280149.wav",
      "/samples/truncated_database/truncated_kick/1678900933.6718366.wav",
      "/samples/truncated_database/truncated_kick/1678902588.1775541.wav",
      "/samples/truncated_database/truncated_kick/1678904992.270483.wav",
      "/samples/truncated_database/truncated_kick/1678908398.3472.wav",
      "/samples/truncated_database/truncated_kick/1678919687.6189938.wav",
      "/samples/truncated_database/truncated_kick/1678921289.1043.wav",
      "/samples/truncated_database/truncated_kick/1678933977.2363677.wav",
    ],
  },
  {
    id: "open_hihat_id",
    type: "open_hihat",
    sounds: [
      "/samples/truncated_database/truncated_open_hihat/1678843534.5507.wav",
      "/samples/truncated_database/truncated_open_hihat/1678847846.1573105.wav",
      "/samples/truncated_database/truncated_open_hihat/1678851253.6752465.wav",
      "/samples/truncated_database/truncated_open_hihat/1678854932.3505948.wav",
      "/samples/truncated_database/truncated_open_hihat/1678856505.8790438.wav",
      "/samples/truncated_database/truncated_open_hihat/1678858458.7775593.wav",
      "/samples/truncated_database/truncated_open_hihat/1678862615.8081427.wav",
      "/samples/truncated_database/truncated_open_hihat/1678865412.6237955.wav",
      "/samples/truncated_database/truncated_open_hihat/1678871302.1180656.wav",
      "/samples/truncated_database/truncated_open_hihat/1678873056.6530824.wav",
      "/samples/truncated_database/truncated_open_hihat/1678886456.0178611.wav",
      "/samples/truncated_database/truncated_open_hihat/1678886456.0178611.wav",
      "/samples/truncated_database/truncated_open_hihat/1678887355.5629833.wav",
      "/samples/truncated_database/truncated_open_hihat/1678893651.5444527.wav",
      "/samples/truncated_database/truncated_open_hihat/1678896846.842627.wav",
      "/samples/truncated_database/truncated_open_hihat/1678903795.2767742.wav",
      "/samples/truncated_database/truncated_open_hihat/1678904311.1912546.wav",
      "/samples/truncated_database/truncated_open_hihat/1678906658.0230045.wav",
      "/samples/truncated_database/truncated_open_hihat/1678908020.0441246.wav",
      "/samples/truncated_database/truncated_open_hihat/1678916723.6825078.wav",
      "/samples/truncated_database/truncated_open_hihat/1678917395.534549.wav",
      "/samples/truncated_database/truncated_open_hihat/1678918500.2229996.wav",
      "/samples/truncated_database/truncated_open_hihat/1678923078.817945.wav",
      "/samples/truncated_database/truncated_open_hihat/1678925198.0436902.wav",
      "/samples/truncated_database/truncated_open_hihat/1678928926.9761052.wav",
    ],
  },
  {
    id: "sfx_id",
    type: "sfx",
    sounds: [
      "/samples/truncated_database/truncated_sfx/1678848191.5248933.wav",
      "/samples/truncated_database/truncated_sfx/1678850675.3585765.wav",
      "/samples/truncated_database/truncated_sfx/1678852318.3458037.wav",
      "/samples/truncated_database/truncated_sfx/1678856226.6518285.wav",
      "/samples/truncated_database/truncated_sfx/1678858306.0399256.wav",
      "/samples/truncated_database/truncated_sfx/1678859709.2719266.wav",
      "/samples/truncated_database/truncated_sfx/1678861706.989336.wav",
      "/samples/truncated_database/truncated_sfx/1678861975.6811152.wav",
      "/samples/truncated_database/truncated_sfx/1678864162.3890977.wav",
      "/samples/truncated_database/truncated_sfx/1678867444.3642428.wav",
      "/samples/truncated_database/truncated_sfx/1678869566.91995.wav",
      "/samples/truncated_database/truncated_sfx/1678871357.2078218.wav",
      "/samples/truncated_database/truncated_sfx/1678872472.6971292.wav",
      "/samples/truncated_database/truncated_sfx/1678873767.5728285.wav",
      "/samples/truncated_database/truncated_sfx/1678882754.9194643.wav",
      "/samples/truncated_database/truncated_sfx/1678883630.100728.wav",
      "/samples/truncated_database/truncated_sfx/1678888123.5429688.wav",
      "/samples/truncated_database/truncated_sfx/1678898844.9214597.wav",
      "/samples/truncated_database/truncated_sfx/1678901035.2067196.wav",
      "/samples/truncated_database/truncated_sfx/1678906177.9597.wav",
      "/samples/truncated_database/truncated_sfx/1678917581.397688.wav",
      "/samples/truncated_database/truncated_sfx/1678918503.416245.wav",
      "/samples/truncated_database/truncated_sfx/1678918503.416245.wav",
      "/samples/truncated_database/truncated_sfx/1678919712.7099273.wav",
      "/samples/truncated_database/truncated_sfx/1678925190.1738636.wav",
      "/samples/truncated_database/truncated_sfx/1678927128.060945.wav",
    ],
  },
  {
    id: "theme_id",
    type: "theme",
    sounds: [
      "/samples/truncated_database/truncated_theme/1678843155.6957362.wav",
      "/samples/truncated_database/truncated_theme/1678843277.4496279.wav",
      "/samples/truncated_database/truncated_theme/1678844430.4608195.wav",
      "/samples/truncated_database/truncated_theme/1678851426.4017496.wav",
      "/samples/truncated_database/truncated_theme/1678853354.9859726.wav",
      "/samples/truncated_database/truncated_theme/1678855132.321822.wav",
      "/samples/truncated_database/truncated_theme/1678868298.1509137.wav",
      "/samples/truncated_database/truncated_theme/1678875072.6970248.wav",
      "/samples/truncated_database/truncated_theme/1678875997.3855994.wav",
      "/samples/truncated_database/truncated_theme/1678879072.4679003.wav",
      "/samples/truncated_database/truncated_theme/1678885269.6603184.wav",
      "/samples/truncated_database/truncated_theme/1678896212.6504645.wav",
      "/samples/truncated_database/truncated_theme/1678901393.732737.wav",
      "/samples/truncated_database/truncated_theme/1678875072.6970248.wav",
      "/samples/truncated_database/truncated_theme/1678875997.3855994.wav",
      "/samples/truncated_database/truncated_theme/1678879072.4679003.wav",
      "/samples/truncated_database/truncated_theme/1678885269.6603184.wav",
      "/samples/truncated_database/truncated_theme/1678896212.6504645.wav",
      "/samples/truncated_database/truncated_theme/1678901393.732737.wav",
      "/samples/truncated_database/truncated_theme/1678904583.0571024.wav",
      "/samples/truncated_database/truncated_theme/1678909568.114336.wav",
      "/samples/truncated_database/truncated_theme/1678915305.4083247.wav",
      "/samples/truncated_database/truncated_theme/1678916786.5957377.wav",
      "/samples/truncated_database/truncated_theme/1678919216.2708344.wav",
      "/samples/truncated_database/truncated_theme/1678921286.6780326.wav",
      "/samples/truncated_database/truncated_theme/1678923110.0044239.wav",
      "/samples/truncated_database/truncated_theme/1678925645.824886.wav",
      "/samples/truncated_database/truncated_theme/1678933654.5444217.wav",
      "/samples/truncated_database/truncated_theme/1678935848.0439222.wav",
      "/samples/truncated_database/truncated_theme/1678936300.5224493.wav",
      "/samples/truncated_database/truncated_theme/1678937227.0295975.wav",
    ],
  },
];
