import React from "react";

// eslint-disable-next-line react/require-default-props
function LivePadsNavIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_888_10107)">
        <path
          opacity="0.5"
          d="M12.1463 40.8346C11.5848 40.8346 11.0579 40.7771 10.5344 40.6549C8.82646 40.2569 7.28991 39.37 6.09103 38.0897C4.57793 36.4738 3.98407 34.258 2.80138 29.8432L1.83859 26.2488C0.655338 21.8339 0.0614806 19.6181 0.564362 17.4626C0.962872 15.7547 1.84975 14.2181 3.12956 13.0193C4.74537 11.5061 6.96117 10.9123 11.3755 9.7296C12.1569 9.5203 12.8942 9.32272 13.5561 9.15137L14.2549 11.8533C13.6047 12.0213 12.873 12.2172 12.0988 12.4248C8.13326 13.4875 6.14294 14.0206 5.03783 15.0559C4.16211 15.8764 3.55486 16.9273 3.28249 18.0961C2.93868 19.5712 3.4717 21.5615 4.53439 25.5265L5.49774 29.1209C6.56043 33.086 7.09345 35.0768 8.12879 36.1819C8.94925 37.0577 10.0002 37.6644 11.169 37.9367C12.6441 38.2805 14.6344 37.7475 18.6 36.6848C22.5661 35.6221 24.5559 35.0885 25.661 34.0538C26.4022 33.36 26.9603 32.4826 27.2751 31.5176L29.928 32.3838C29.4675 33.7942 28.6515 35.0763 27.5681 36.0904C25.9529 37.6035 23.7371 38.1974 19.3233 39.3801C15.9795 40.2759 13.8971 40.834 12.1468 40.834L12.1463 40.8346Z"
          fill="#0BD1C9"
        />
        <path
          d="M27.8514 33.3919C26.0972 33.3919 24.0259 32.8372 20.6804 31.9408C16.2633 30.757 14.0475 30.1631 12.4323 28.6506C11.1525 27.4517 10.265 25.9151 9.8671 24.2072C9.36421 22.0512 9.95807 19.8354 11.1413 15.421L12.1047 11.8266C12.2654 11.2272 12.4172 10.6607 12.5601 10.1366C13.3822 7.12658 14.0235 5.04417 15.3943 3.58073C16.5932 2.30092 18.1298 1.41349 19.8377 1.01553C21.9932 0.512652 24.209 1.10651 28.6244 2.28976C33.0393 3.47301 35.2551 4.06631 36.8709 5.57942C38.1512 6.7783 39.0381 8.31485 39.4361 10.0227C39.939 12.1783 39.3451 14.3946 38.1619 18.8089L37.1985 22.4033C36.0158 26.8176 35.422 29.0335 33.9088 30.6498C32.71 31.9296 31.1734 32.8171 29.4655 33.215C29.2395 33.268 29.0056 33.3093 28.7712 33.3378C28.4715 33.3746 28.1667 33.3925 27.8519 33.3925L27.8514 33.3919ZM21.4518 3.62817C21.0951 3.62817 20.772 3.66166 20.4706 3.73199C19.3018 4.00436 18.2509 4.61161 17.4304 5.48733C16.5748 6.401 16.0663 7.88676 15.2514 10.8711C15.1102 11.3885 14.959 11.9517 14.7994 12.5483L13.836 16.1427C12.7733 20.1077 12.2403 22.098 12.5841 23.5732C12.8565 24.7419 13.4637 25.7929 14.3394 26.6134C15.4446 27.6487 17.4343 28.1817 21.3988 29.2439C25.0099 30.2117 26.9974 30.7436 28.4302 30.5672C28.5686 30.5505 28.6992 30.527 28.8309 30.4963C30.0002 30.224 31.0512 29.6167 31.8716 28.741C32.907 27.6353 33.4406 25.645 34.5027 21.68L35.466 18.0856C36.5287 14.1206 37.0618 12.1303 36.7179 10.6551C36.4456 9.48638 35.8389 8.43541 34.9626 7.61495C33.8569 6.5796 31.8672 6.04658 27.9016 4.98389C24.7465 4.13831 22.8415 3.62817 21.4518 3.62817Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_888_10107">
          <rect
            width="39.2298"
            height="40"
            fill="white"
            transform="translate(0.384766 0.834961)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LivePadsNavIcon;
