import React from "react";

// eslint-disable-next-line react/require-default-props
function EditPenIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_836_13281)">
        <path
          opacity="0.5"
          d="M1.84149 15.8359L0.445312 14.4397L1.4321 13.4529L2.82828 14.8491L1.84149 15.8359ZM13.9949 7.08041C13.9243 7.07622 12.2474 6.9618 10.7834 5.49781C9.31941 4.03382 9.20499 2.35689 9.20081 2.28629L10.5936 2.20424V2.19922C10.597 2.24917 10.6958 3.43661 11.7699 4.51102C12.8457 5.58683 14.0647 5.68674 14.0767 5.6873L13.9946 7.08013L13.9949 7.08041Z"
          fill="#0BD1C9"
        />
        <path
          opacity="0.5"
          d="M15.5832 19.9998H0.699624C0.314231 19.9998 0.00195312 19.6876 0.00195312 19.3022C0.00195312 18.9168 0.314231 18.6045 0.699624 18.6045H15.5832C15.9686 18.6045 16.2809 18.9168 16.2809 19.3022C16.2809 19.6876 15.9686 19.9998 15.5832 19.9998Z"
          fill="#0BD1C9"
        />
        <path
          d="M1.48445 16.28C1.09766 16.28 0.721197 16.1282 0.436547 15.8436C0.0358047 15.4428 -0.102055 14.8598 0.0773859 14.3221L1.0904 11.2833C1.29133 10.6805 1.39208 10.378 1.5397 10.0682C1.71356 9.70321 1.92789 9.35661 2.17654 9.03791C2.38779 8.76694 2.61328 8.54173 3.0623 8.09271L10.0934 1.06158C10.7777 0.377021 11.688 0 12.6558 0C13.6236 0 14.534 0.377021 15.2185 1.06158C16.6314 2.4745 16.6314 4.77375 15.2185 6.18695L8.1874 13.2181C7.7381 13.6674 7.51261 13.8926 7.24219 14.1038C6.92349 14.3525 6.57689 14.5668 6.21187 14.7407C5.90155 14.8886 5.59931 14.9893 4.99681 15.19L1.95803 16.203C1.80203 16.2549 1.6424 16.2803 1.48445 16.2803V16.28ZM11.0799 2.04808L4.04881 9.07921C3.64025 9.48777 3.43514 9.69288 3.27663 9.89605C3.09021 10.1349 2.92974 10.3947 2.79914 10.6685C2.68835 10.901 2.59682 11.1761 2.41403 11.7245L1.40101 14.7633C1.38984 14.7965 1.3985 14.8322 1.42305 14.8568C1.44789 14.8813 1.48361 14.89 1.51654 14.8788L4.5556 13.8658C5.10369 13.6833 5.37885 13.5915 5.61159 13.4807C5.88536 13.3504 6.14517 13.1896 6.38405 13.0035C6.58694 12.8452 6.79205 12.6401 7.20061 12.2316L14.232 5.20016C15.101 4.33114 15.101 2.9171 14.232 2.04808C13.8112 1.62725 13.2514 1.39534 12.6561 1.39534C12.0609 1.39534 11.5011 1.62725 11.0802 2.04808H11.0799Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_836_13281">
          <rect width="16.28" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EditPenIcon;
