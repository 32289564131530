import React from "react";
// eslint-disable-next-line react/require-default-props
function AiDjIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_885_15712)">
        <path
          opacity="0.5"
          d="M17.1624 13.1934H15.9066V9.0004C15.9066 5.18552 12.8083 2.08209 8.99967 2.08209C5.19105 2.08209 2.09272 5.18552 2.09272 9.0004V13.1934H0.836914V9.0004C0.836914 6.81648 1.68609 4.76313 3.22772 3.21896C4.7696 1.67479 6.81933 0.824219 8.99967 0.824219C11.18 0.824219 13.23 1.67479 14.7716 3.21896C16.3132 4.76313 17.1624 6.81648 17.1624 9.0004V13.1934Z"
          fill="#0BD1C9"
        />
        <path
          opacity="0.5"
          d="M17.372 14.2418C17.0252 14.2418 16.7441 13.9603 16.7441 13.6128V11.9356C16.7441 11.5882 17.0252 11.3066 17.372 11.3066C17.7189 11.3066 17.9999 11.5882 17.9999 11.9356V13.6128C17.9999 13.9603 17.7189 14.2418 17.372 14.2418Z"
          fill="#0BD1C9"
        />
        <path
          opacity="0.5"
          d="M0.627904 14.2418C0.28105 14.2418 0 13.9603 0 13.6128V11.9356C0 11.5882 0.28105 11.3066 0.627904 11.3066C0.974758 11.3066 1.25581 11.5882 1.25581 11.9356V13.6128C1.25581 13.9603 0.974758 14.2418 0.627904 14.2418Z"
          fill="#0BD1C9"
        />
        <path
          d="M4.85575 17.1763C4.55612 17.1763 4.20901 17.1079 3.76496 17.0201C2.67793 16.8055 2.07941 16.6872 1.60371 16.248C1.42714 16.085 1.27645 15.8955 1.15614 15.6849C0.836914 15.1267 0.836914 14.4975 0.836914 13.3554V12.0424C0.836914 10.8909 0.836914 10.2567 1.14961 9.70275C1.3101 9.41872 1.52208 9.17595 1.77952 8.98149C2.29014 8.59632 2.90196 8.53595 4.0126 8.42626C4.71033 8.35733 5.13128 8.31582 5.53263 8.54727C5.73055 8.66148 5.90234 8.82425 6.02918 9.01746C6.27858 9.39734 6.27858 9.83005 6.27858 10.5473V14.8792C6.27858 15.7468 6.27858 16.2246 5.9576 16.6359C5.83679 16.7909 5.68936 16.9179 5.51957 17.0138C5.31161 17.131 5.09913 17.1763 4.8555 17.1763H4.85575ZM4.78919 9.62879C4.63649 9.62879 4.38357 9.65369 4.13668 9.6781C3.25158 9.76539 2.76458 9.81344 2.53552 9.98627C2.41672 10.0761 2.31827 10.189 2.24317 10.3221C2.09297 10.588 2.09297 11.1045 2.09297 12.0426V13.3556C2.09297 14.2822 2.09297 14.7924 2.24593 15.06C2.30244 15.1589 2.37277 15.2475 2.4549 15.3232C2.66989 15.5217 3.14509 15.6158 4.00783 15.7861C4.3793 15.8595 4.79974 15.9423 4.909 15.9146C4.93135 15.9011 4.95144 15.8832 4.96852 15.8616C5.02328 15.7682 5.02328 15.296 5.02328 14.8794V10.5475C5.02328 10.1928 5.02328 9.7908 4.97957 9.70753C4.96149 9.68011 4.93738 9.65621 4.91 9.6396C4.88589 9.63206 4.8442 9.62879 4.78919 9.62879Z"
          fill="white"
        />
        <path
          d="M13.144 17.1759C12.9002 17.1759 12.6877 17.1306 12.4797 17.0134C12.3099 16.9178 12.1625 16.7905 12.0414 16.6355C11.7207 16.2244 11.7207 15.7467 11.7207 14.879V10.5472C11.7207 9.83017 11.7207 9.39746 11.9701 9.01733C12.0969 8.82413 12.2687 8.66161 12.4667 8.54739C12.8678 8.31569 13.2887 8.35745 13.9862 8.42613C15.0973 8.53582 15.7091 8.5962 16.2195 8.98136C16.4769 9.17583 16.6889 9.41834 16.8494 9.70262C17.1624 10.2566 17.1624 10.8908 17.1624 12.0423V13.3552C17.1624 14.4974 17.1624 15.1266 16.8431 15.6848C16.7228 15.8954 16.5721 16.0851 16.3953 16.2481C15.9201 16.6871 15.3214 16.8053 14.2343 17.0199C13.7903 17.1075 13.4434 17.1762 13.1438 17.1762L13.144 17.1759ZM13.0905 15.9143C13.1998 15.9419 13.6202 15.8589 13.9917 15.7857C14.8544 15.6154 15.3296 15.5216 15.5446 15.3231C15.627 15.2471 15.6973 15.1585 15.7536 15.0599C15.9068 14.792 15.9068 14.2818 15.9068 13.3552V12.0423C15.9068 11.1044 15.9068 10.5877 15.7566 10.3218C15.6815 10.1887 15.5831 10.0757 15.464 9.9859C15.2352 9.81332 14.748 9.76527 13.8636 9.67797C13.5278 9.64476 13.181 9.61055 13.09 9.63948C13.0627 9.65608 13.0385 9.67998 13.0197 9.70866C12.9768 9.79042 12.9768 10.1927 12.9768 10.5474V14.8793C12.9768 15.2959 12.9768 15.7681 13.0328 15.8634C13.0486 15.8836 13.0684 15.9012 13.091 15.9148L13.0905 15.9143Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_885_15712">
          <rect
            width="18"
            height="16.3521"
            fill="white"
            transform="translate(0 0.824219)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AiDjIcon;
