import React from "react";

// eslint-disable-next-line react/require-default-props
function RecordMusicIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_836_13300)">
        <path
          opacity="0.5"
          d="M8.99987 18C7.78526 18 6.60656 17.7619 5.49642 17.2925C4.42471 16.8391 3.46226 16.1904 2.63594 15.3641C1.80962 14.5377 1.16087 13.5753 0.707522 12.5036C0.238101 11.3934 0 10.2147 0 8.99987C0 7.78501 0.238101 6.60656 0.707522 5.49642C1.16087 4.42471 1.80962 3.46226 2.63594 2.63594C3.46226 1.80962 4.42471 1.16087 5.49667 0.707522C6.60656 0.238101 7.78526 0 8.99987 0C10.2145 0 11.3932 0.238101 12.5033 0.707522C13.575 1.16087 14.5375 1.80962 15.3638 2.63594C16.1901 3.46226 16.8389 4.42471 17.2922 5.49642C17.7616 6.6063 17.9997 7.78501 17.9997 8.99987C17.9997 10.2147 17.7616 11.3932 17.2922 12.5033C16.8389 13.575 16.1901 14.5375 15.3638 15.3638C14.5375 16.1901 13.575 16.8389 12.5033 17.2922C11.3934 17.7616 10.2147 17.9997 8.99987 17.9997V18ZM8.99987 1.25581C4.72988 1.25581 1.25581 4.72988 1.25581 8.99987C1.25581 13.2699 4.72988 16.7439 8.99987 16.7439C13.2699 16.7439 16.7439 13.2699 16.7439 8.99987C16.7439 4.72988 13.2701 1.25581 8.99987 1.25581Z"
          fill="#0BD1C9"
        />
        <path
          d="M9.00008 12.9767C6.80744 12.9767 5.02344 11.1927 5.02344 9.00008C5.02344 6.80744 6.80744 5.02344 9.00008 5.02344C11.1927 5.02344 12.9767 6.80744 12.9767 9.00008C12.9767 11.1927 11.1927 12.9767 9.00008 12.9767ZM9.00008 6.27899C7.49989 6.27899 6.27925 7.49964 6.27925 8.99983C6.27925 10.5 7.49989 11.7207 9.00008 11.7207C10.5003 11.7207 11.7209 10.5 11.7209 8.99983C11.7209 7.49964 10.5003 6.27899 9.00008 6.27899Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_836_13300">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default RecordMusicIcon;
