import React from "react";

// eslint-disable-next-line react/require-default-props
function SaveMusicIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_836_13311)">
        <path
          opacity="0.7"
          d="M11.9309 9.16918C11.7834 9.16918 11.634 9.15185 11.4853 9.11569C11.097 9.02125 10.76 8.7618 10.0215 8.19317C9.70784 7.9518 9.53529 7.81869 9.41649 7.7672C9.15151 7.65217 8.84736 7.65217 8.58238 7.7672C8.46358 7.81869 8.29104 7.9518 7.97759 8.19317C7.23917 8.7618 6.90211 9.02125 6.51381 9.11569C6.36513 9.15185 6.21569 9.16918 6.06825 9.16918C5.33763 9.16918 4.65547 8.74246 4.3478 8.04976C4.18555 7.68482 4.18555 7.25935 4.18555 6.32729V0.62793H5.44136V6.32729C5.44136 6.91275 5.44135 7.41834 5.49535 7.54015C5.61867 7.81768 5.92208 7.96712 6.21744 7.89554C6.34679 7.86415 6.7474 7.55547 7.21129 7.19832C7.60411 6.89592 7.82036 6.72915 8.08182 6.61562C8.66501 6.3622 9.33361 6.36245 9.9168 6.61562C10.1783 6.72915 10.3945 6.89592 10.7876 7.19832C11.2517 7.55572 11.6521 7.86415 11.7817 7.89554C12.0765 7.96712 12.3802 7.81768 12.5035 7.54015C12.5575 7.41859 12.5575 6.91275 12.5575 6.32729V0.62793H13.8133V6.32729C13.8133 7.25935 13.8133 7.68482 13.6513 8.04976C13.3437 8.74246 12.6612 9.16918 11.9309 9.16918Z"
          fill="#0BD1C9"
        />
        <path
          d="M8.99987 18C6.97074 18 5.50446 18 4.32299 17.841C2.9951 17.6624 2.0967 17.2767 1.41002 16.59C0.723345 15.9033 0.337561 15.0049 0.158985 13.6768C0 12.4953 0 11.029 0 8.99987C0 6.97074 0 5.50471 0.158734 4.32299C0.33731 2.9951 0.723094 2.0967 1.40977 1.40977C2.09645 0.722843 2.9951 0.33731 4.32299 0.158734C5.50471 0 6.97074 0 8.99987 0C11.029 0 12.4953 0 13.6768 0.158734C15.0046 0.33731 15.9031 0.723094 16.59 1.40977C17.2767 2.09645 17.6627 2.99485 17.841 4.32299C18 5.50446 18 6.97074 18 8.99987C18 11.029 18 12.4953 17.841 13.6768C17.6624 15.0046 17.2767 15.9033 16.59 16.59C15.9033 17.2767 15.0049 17.6624 13.6768 17.841C12.4953 18 11.029 18 8.99987 18ZM8.99987 1.25581C5.23145 1.25581 3.33995 1.25581 2.29788 2.29788C1.25581 3.33995 1.25581 5.23145 1.25581 8.99987C1.25581 12.7683 1.25581 14.6598 2.29788 15.7019C3.33995 16.7439 5.23145 16.7439 8.99987 16.7439C12.7683 16.7439 14.6598 16.7439 15.7019 15.7019C16.7439 14.6598 16.7439 12.7683 16.7439 8.99987C16.7439 5.23145 16.7439 3.33995 15.7019 2.29788C14.6598 1.25581 12.7683 1.25581 8.99987 1.25581Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_836_13311">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SaveMusicIcon;
