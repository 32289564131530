import React from "react";

// eslint-disable-next-line react/require-default-props
function AvatarIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="0.5" width="59" height="59" rx="29.5" fill="#252532" />
      <path
        opacity="0.4"
        d="M40.0206 34.8203L36.8906 27.5003C36.3206 26.1603 35.4706 25.4003 34.5006 25.3503C33.5406 25.3003 32.6106 25.9703 31.9006 27.2503L30.0006 30.6603C29.6006 31.3803 29.0306 31.8103 28.4106 31.8603C27.7806 31.9203 27.1506 31.5903 26.6406 30.9403L26.4206 30.6603C25.7106 29.7703 24.8306 29.3403 23.9306 29.4303C23.0306 29.5203 22.2606 30.1403 21.7506 31.1503L20.0206 34.6003C19.4006 35.8503 19.4606 37.3003 20.1906 38.4803C20.9206 39.6603 22.1906 40.3703 23.5806 40.3703H36.3406C37.6806 40.3703 38.9306 39.7003 39.6706 38.5803C40.4306 37.4603 40.5506 36.0503 40.0206 34.8203Z"
        fill="white"
      />
      <path
        d="M24.9698 26.3801C26.8366 26.3801 28.3498 24.8668 28.3498 23.0001C28.3498 21.1334 26.8366 19.6201 24.9698 19.6201C23.1031 19.6201 21.5898 21.1334 21.5898 23.0001C21.5898 24.8668 23.1031 26.3801 24.9698 26.3801Z"
        fill="white"
      />
      <rect x="0.5" y="0.5" width="59" height="59" rx="29.5" stroke="#252532" />
    </svg>
  );
}

export default AvatarIcon;
