import React from "react";

// eslint-disable-next-line react/require-default-props
function CloseSettingsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_836_5022)">
        <path
          opacity="0.5"
          d="M14.9998 30C12.9754 30 11.0109 29.6032 9.1607 28.8208C7.37452 28.0652 5.77044 26.984 4.39324 25.6068C3.01603 24.2296 1.93478 22.6255 1.1792 20.8393C0.396835 18.9891 0 17.0246 0 14.9998C0 12.975 0.396835 11.0109 1.1792 9.1607C1.93478 7.37452 3.01603 5.77044 4.39324 4.39324C5.77044 3.01603 7.37452 1.93478 9.16112 1.1792C11.0109 0.396835 12.9754 0 14.9998 0C17.0242 0 18.9887 0.396835 20.8389 1.1792C22.6251 1.93478 24.2291 3.01603 25.6063 4.39324C26.9836 5.77044 28.0648 7.37452 28.8204 9.1607C29.6027 11.0105 29.9996 12.975 29.9996 14.9998C29.9996 17.0246 29.6027 18.9887 28.8204 20.8389C28.0648 22.6251 26.9836 24.2291 25.6063 25.6063C24.2291 26.9836 22.6251 28.0648 20.8389 28.8204C18.9891 29.6027 17.0246 29.9996 14.9998 29.9996V30ZM14.9998 2.09301C7.88313 2.09301 2.09301 7.88313 2.09301 14.9998C2.09301 22.1165 7.88313 27.9066 14.9998 27.9066C22.1165 27.9066 27.9066 22.1165 27.9066 14.9998C27.9066 7.88313 22.1169 2.09301 14.9998 2.09301Z"
          fill="#0BD1C9"
        />
        <path
          d="M11.5114 19.5349C11.2434 19.5349 10.9755 19.4327 10.7713 19.2284C10.3627 18.8199 10.3627 18.1572 10.7713 17.7483L13.5198 15.0001L10.7713 12.2516C10.3627 11.843 10.3627 11.1804 10.7713 10.7714C11.1798 10.3624 11.8425 10.3629 12.2514 10.7714L15 13.52L17.7485 10.7714C18.1571 10.3629 18.8197 10.3629 19.2287 10.7714C19.6373 11.18 19.6373 11.8426 19.2287 12.2516L16.4802 14.9997L19.2287 17.7483C19.6373 18.1568 19.6373 18.8195 19.2287 19.2284C18.8202 19.637 18.1575 19.637 17.7485 19.2284L15 16.4799L12.2514 19.2284C12.0472 19.4327 11.7793 19.5349 11.5114 19.5349Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_836_5022">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CloseSettingsIcon;
