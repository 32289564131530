import React from "react";

// eslint-disable-next-line react/require-default-props
function ShareIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_885_15764)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5898 0C11.7489 0 10.2566 1.49231 10.2566 3.33323C10.2566 3.53415 10.2745 3.73108 10.3086 3.92215L5.45662 7.31846C5.432 7.33569 5.40862 7.35415 5.38677 7.37354C4.82092 6.93046 4.108 6.66646 3.33385 6.66646C1.49231 6.66677 0 8.15908 0 10C0 11.8409 1.49231 13.3332 3.33323 13.3332C4.10769 13.3332 4.82031 13.0692 5.38615 12.6262C5.408 12.6455 5.43139 12.664 5.456 12.6812L10.308 16.0775C10.2738 16.2686 10.256 16.4655 10.256 16.6665C10.256 18.5074 11.7483 19.9997 13.5892 19.9997C15.4302 19.9997 16.9225 18.5074 16.9225 16.6665C16.9225 14.8255 15.4302 13.3332 13.5892 13.3332C12.5095 13.3332 11.5495 13.8468 10.9403 14.6428L6.34615 11.4268C6.55138 10.9942 6.66615 10.5105 6.66615 10C6.66615 9.48954 6.55138 9.00585 6.34615 8.57323L10.9403 5.35723C11.5495 6.15323 12.5092 6.66677 13.5892 6.66677C15.4302 6.66677 16.9225 5.17446 16.9225 3.33354C16.9225 1.49262 15.4308 0 13.5898 0ZM11.7951 3.33323C11.7951 2.34185 12.5988 1.53846 13.5898 1.53846C14.5809 1.53846 15.3846 2.34215 15.3846 3.33323C15.3846 4.32431 14.5809 5.128 13.5898 5.128C12.5988 5.128 11.7951 4.32431 11.7951 3.33323ZM3.33323 8.20523C2.34185 8.20523 1.53846 9.00892 1.53846 10C1.53846 10.9911 2.34215 11.7948 3.33323 11.7948C4.32431 11.7948 5.128 10.9911 5.128 10C5.128 9.00892 4.32431 8.20523 3.33323 8.20523ZM13.5895 14.872C12.5982 14.872 11.7948 15.6757 11.7948 16.6668C11.7948 17.6578 12.5985 18.4615 13.5895 18.4615C14.5806 18.4615 15.3843 17.6578 15.3843 16.6668C15.3843 15.6757 14.5806 14.872 13.5895 14.872Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_885_15764">
          <rect width="16.9231" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ShareIcon;
