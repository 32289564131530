import React from "react";

// eslint-disable-next-line react/require-default-props
function UploadIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_885_15397)">
        <path
          opacity="0.5"
          d="M12.3505 17.9999H5.65284C3.19479 17.9999 1.84186 17.9999 0.92093 17.079C0 16.158 0 14.8051 0 12.347V11.5098C0 9.05179 0 7.69886 0.92093 6.77793C1.76149 5.93737 2.95033 5.8704 4.81228 5.86035H4.81563C5.16056 5.86035 5.44186 6.13831 5.44521 6.48324C5.44521 6.83151 5.16726 7.11282 4.82233 7.11617C3.07758 7.12621 2.27386 7.20324 1.81172 7.66537C1.25916 8.21793 1.25916 9.31635 1.25916 11.5098V12.347C1.25916 14.5372 1.25916 15.639 1.81172 16.1915C2.36428 16.7441 3.4627 16.7441 5.65619 16.7441H12.3539C14.544 16.7441 15.6458 16.7441 16.1983 16.1915C16.7509 15.639 16.7509 14.5405 16.7509 12.347V11.5098C16.7509 9.3197 16.7509 8.21793 16.1983 7.66537C15.7362 7.20324 14.9325 7.12621 13.1877 7.11617C12.8394 7.11617 12.5615 6.83151 12.5648 6.48324C12.5648 6.13831 12.8461 5.86035 13.1944 5.86035H13.1978C15.0564 5.8704 16.2486 5.93737 17.0891 6.77793C18.01 7.69886 18.01 9.05179 18.01 11.5098V12.347C18.01 14.8051 18.01 16.158 17.0891 17.079C16.1682 17.9999 14.8153 17.9999 12.3572 17.9999H12.3505Z"
          fill="white"
        />
        <path
          d="M9.0014 12.1395C8.65312 12.1395 8.37182 11.8582 8.37182 11.51V2.32409L6.96531 3.96502C6.74094 4.22958 6.34242 4.25972 6.08121 4.032C5.81666 3.80763 5.78652 3.40912 6.01424 3.14791L8.51582 0.227721C8.51582 0.227721 8.54261 0.197581 8.55601 0.184186C8.60624 0.133953 8.66317 0.0937674 8.72345 0.0636279C8.74354 0.0535814 8.76028 0.0468837 8.78038 0.040186C8.82056 0.0234419 8.8641 0.0133953 8.91098 0.00669767C8.94112 0.00334884 8.96791 0 8.99805 0C9.02819 0 9.06168 0 9.09182 0.00669767C9.12531 0.0133953 9.1588 0.020093 9.19228 0.0301395C9.21908 0.040186 9.24587 0.0502326 9.26931 0.0602791C9.32959 0.0904186 9.38987 0.130605 9.4401 0.180837C9.45349 0.194233 9.46689 0.210977 9.48028 0.224372L11.9852 3.14456C12.2096 3.40912 12.1794 3.80428 11.9182 4.02865C11.6537 4.25302 11.2585 4.22288 11.0341 3.96167L9.62763 2.32074V11.5066C9.62763 11.8549 9.34633 12.1362 8.99805 12.1362L9.0014 12.1395Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_885_15397">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default UploadIcon;
