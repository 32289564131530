import React from "react";

// eslint-disable-next-line react/require-default-props
function GenerationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_888_10102)">
        <path
          d="M20.0008 40.835C19.062 40.835 18.3013 40.0676 18.3013 39.1207V33.6349C18.3013 32.6879 19.062 31.9206 20.0008 31.9206C20.9396 31.9206 21.7003 32.6879 21.7003 33.6349V39.1207C21.7003 40.0676 20.9396 40.835 20.0008 40.835ZM38.1292 22.5489H32.6907C31.7519 22.5489 30.9912 21.7816 30.9912 20.8346C30.9912 19.8876 31.7519 19.1203 32.6907 19.1203H38.1292C39.068 19.1203 39.8287 19.8876 39.8287 20.8346C39.8287 21.7816 39.068 22.5489 38.1292 22.5489ZM7.3115 22.5489H1.87237C0.933555 22.5489 0.172852 21.7816 0.172852 20.8346C0.172852 19.8876 0.933555 19.1203 1.87237 19.1203H7.31082C8.24964 19.1203 9.01034 19.8876 9.01034 20.8346C9.01034 21.7816 8.24964 22.5489 7.31082 22.5489H7.3115ZM20.0015 9.74861C19.0626 9.74861 18.3019 8.98129 18.3019 8.03432V2.54926C18.3019 1.60228 19.0626 0.834961 20.0015 0.834961C20.9403 0.834961 21.701 1.60228 21.701 2.54926V8.035C21.701 8.98198 20.9403 9.7493 20.0015 9.7493V9.74861Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M32.8188 35.4788C32.3837 35.4788 31.9487 35.3115 31.6169 34.9769L27.7712 31.0978C27.1078 30.4285 27.1078 29.343 27.7712 28.6738C28.4347 28.0045 29.5109 28.0045 30.1744 28.6738L34.02 32.5529C34.6835 33.2221 34.6835 34.3076 34.02 34.9776C33.6883 35.3122 33.2539 35.4788 32.8188 35.4788ZM7.18262 35.4788C6.74754 35.4788 6.31247 35.3115 5.98072 34.9769C5.31723 34.3076 5.31723 33.2221 5.98072 32.5522L9.82639 28.6731C10.4899 28.0038 11.566 28.0038 12.2302 28.6731C12.8937 29.3423 12.8937 30.4278 12.2302 31.0978L8.38452 34.9769C8.05277 35.3115 7.6177 35.4788 7.18262 35.4788ZM11.0276 13.4981C10.5925 13.4981 10.1575 13.3308 9.82571 12.9962L5.98004 9.11707C5.31655 8.44781 5.31655 7.36232 5.98004 6.69238C6.64353 6.02311 7.71967 6.02311 8.38384 6.69238L12.2295 10.5715C12.893 11.2407 12.893 12.3262 12.2295 12.9962C11.8978 13.3308 11.4627 13.4981 11.0276 13.4981ZM28.9738 13.4981C28.5388 13.4981 28.1037 13.3308 27.7719 12.9962C27.1084 12.3269 27.1084 11.2414 27.7719 10.5715L31.6176 6.69238C32.2811 6.02311 33.3572 6.02311 34.0214 6.69238C34.6849 7.36164 34.6849 8.44713 34.0214 9.11707L30.1757 12.9962C29.844 13.3308 29.4089 13.4981 28.9738 13.4981Z"
          fill="#0BD1C9"
        />
      </g>
      <defs>
        <clipPath id="clip0_888_10102">
          <rect
            width="39.6552"
            height="40"
            fill="white"
            transform="translate(0.172852 0.834961)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default GenerationIcon;
