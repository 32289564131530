import React from "react";

// eslint-disable-next-line react/require-default-props
function PauseMusicIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3327 36.6663C29.5373 36.6663 36.9993 29.2043 36.9993 19.9997C36.9993 10.7949 29.5373 3.33301 20.3327 3.33301C11.1279 3.33301 3.66602 10.7949 3.66602 19.9997C3.66602 29.2043 11.1279 36.6663 20.3327 36.6663Z"
        fill="url(#paint0_linear_1206_20078)"
      />
      <path
        d="M13.7929 14.3619C13.666 14.6682 13.666 15.0564 13.666 15.833V24.1663C13.666 24.9428 13.666 25.3312 13.7929 25.6375C13.962 26.0458 14.2865 26.3703 14.6949 26.5395C15.0012 26.6663 15.3894 26.6663 16.166 26.6663C16.9426 26.6663 17.3308 26.6663 17.6372 26.5395C18.0455 26.3703 18.37 26.0458 18.5392 25.6375C18.666 25.3312 18.666 24.9428 18.666 24.1663V15.833C18.666 15.0564 18.666 14.6682 18.5392 14.3619C18.37 13.9535 18.0455 13.629 17.6372 13.4599C17.3308 13.333 16.9426 13.333 16.166 13.333C15.3894 13.333 15.0012 13.333 14.6949 13.4599C14.2865 13.629 13.962 13.9535 13.7929 14.3619Z"
        fill="white"
      />
      <path
        d="M22.1268 14.3619C22 14.6682 22 15.0564 22 15.833V24.1663C22 24.9428 22 25.3312 22.1268 25.6375C22.296 26.0458 22.6205 26.3703 23.0288 26.5395C23.3352 26.6663 23.7235 26.6663 24.5 26.6663C25.2765 26.6663 25.6648 26.6663 25.9712 26.5395C26.3795 26.3703 26.704 26.0458 26.8732 25.6375C27 25.3312 27 24.9428 27 24.1663V15.833C27 15.0564 27 14.6682 26.8732 14.3619C26.704 13.9535 26.3795 13.629 25.9712 13.4599C25.6648 13.333 25.2765 13.333 24.5 13.333C23.7235 13.333 23.3352 13.333 23.0288 13.4599C22.6205 13.629 22.296 13.9535 22.1268 14.3619Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1206_20078"
          x1="4.0448"
          y1="45.4164"
          x2="48.0656"
          y2="42.4847"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0AD1CE" />
          <stop offset="1" stopColor="#14C156" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default PauseMusicIcon;
