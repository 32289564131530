import React from "react";

// eslint-disable-next-line react/require-default-props
function MuteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.66948 9.76259C1.7006 8.22739 1.71616 7.4598 2.2261 6.8027C2.31918 6.68275 2.45574 6.54025 2.5702 6.44362C3.19732 5.91418 4.02528 5.91418 5.68121 5.91418C6.27283 5.91418 6.56863 5.91418 6.85062 5.83677C6.90922 5.82069 6.96719 5.80214 7.02441 5.78118C7.29979 5.68029 7.54678 5.50665 8.04075 5.15935C9.98966 3.78915 10.9641 3.10406 11.782 3.40168C11.9387 3.45874 12.0906 3.5411 12.2262 3.64269C12.9332 4.17254 12.9869 5.4053 13.0943 7.87081C13.1342 8.78375 13.1612 9.56509 13.1612 9.99967C13.1612 10.4343 13.1342 11.2156 13.0943 12.1285C12.9869 14.594 12.9332 15.8268 12.2262 16.3567C12.0906 16.4583 11.9387 16.5406 11.782 16.5977C10.9641 16.8953 9.98966 16.2102 8.04075 14.84C7.54678 14.4927 7.29979 14.3191 7.02441 14.2182C6.96719 14.1972 6.90922 14.1787 6.85062 14.1626C6.56863 14.0852 6.27283 14.0852 5.68121 14.0852C4.02528 14.0852 3.19732 14.0852 2.5702 13.5558C2.45574 13.4591 2.31918 13.3166 2.2261 13.1967C1.71616 12.5395 1.7006 11.7719 1.66948 10.2368C1.66785 10.1562 1.66699 10.077 1.66699 9.99967C1.66699 9.92234 1.66785 9.84317 1.66948 9.76259Z"
        fill="white"
      />
      <path
        d="M17.8866 12.5002C17.7295 12.5002 17.5725 12.4403 17.4527 12.3205L16.2502 11.118L15.0477 12.3205C14.8081 12.5601 14.4196 12.5601 14.1798 12.3205C13.94 12.0809 13.9402 11.6924 14.1798 11.4526L15.3823 10.2501L14.1798 9.0476C13.9402 8.80804 13.9402 8.41948 14.1798 8.17967C14.4193 7.94011 14.8079 7.94011 15.0477 8.17967L16.2502 9.38216L17.4527 8.17967C17.6922 7.94011 18.0808 7.94011 18.3206 8.17967C18.5604 8.41924 18.5602 8.80779 18.3206 9.0476L17.1181 10.2501L18.3206 11.4526C18.5602 11.6921 18.5602 12.0807 18.3206 12.3205C18.2008 12.4403 18.0437 12.5002 17.8866 12.5002Z"
        fill="white"
      />
    </svg>
  );
}

export default MuteIcon;
